(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/customer-service-smart-faq/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/modules/customer-service-smart-faq/assets/javascripts/init.js');
'use strict';

const smartFaq = document.getElementById('smartfaq');
const header = document.getElementsByClassName('js-fixed-top-content')[0].clientHeight;
const setHeight = () => {
  smartFaq.height = Math.round(window.innerHeight - header);
};
const onLoadEvents = () => {
  if (parseInt(smartFaq.height, 10) > 0) {
    window.requestAnimationFrame(onLoadEvents);
  } else {
    setHeight();
  }

  if (svs && svs.customer_service_smart_faq && svs.customer_service_smart_faq.data && svs.customer_service_smart_faq.data.moduleConfig && svs.customer_service_smart_faq.data.moduleConfig.hideScrollbar || false) {
    document.getElementsByTagName('html')[0].classList.add('hidden-scrollbar');
  }
};
onLoadEvents();
window.addEventListener('resize', setHeight, false);
window.addEventListener('orientationchange', setHeight, false);

 })(window);